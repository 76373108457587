import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import { ToastContainer } from 'react-toastify';

const Login = lazy(() => import('../../Pages/Login'));
const Logout = lazy(() => import('../../Pages/Logout'));
const ForgotPassword = lazy(() => import('../../Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../../Pages/ResetPassword'));
const Management = lazy(() => import('../../Pages/Management'));
const Referral = lazy(() => import('../../Pages/Referral'));
const Specialist = lazy(() => import('../../Pages/Specialist'));
const SpecialistsSearch = lazy(() => import('../../Pages/SpecialistsSearch'));
const SearchResult = lazy(() => import('../../Pages/SpecialistsSearch/SearchResult'));
const Registration = lazy(() => import('../../Pages/Registration'));
const VerifyMail = lazy(() => import('../../Pages/VerifyMail'));
const SpecialistDetailsPage = lazy(() => import('../../Pages/SpecialistsSearch/SpecialistDetails'));
const BookAppointment = lazy(() => import('../../Pages/SpecialistsSearch/BookAppointment'));
const ConfirmAppointment = lazy(() => import('../../Pages/SpecialistsSearch/ConfirmAppointment'));

const PatientDetails = lazy(() => import('../../Pages/SpecialistsSearch/PatientDetails'));
const LabTestSearch = lazy(() => import('../../Pages/LabTest/LabTestSearch'));
const BookLabTest = lazy(() => import('../../Pages/LabTest/BookLabTest'));
const ConfirmLabTest = lazy(() => import('../../Pages/LabTest/ConfirmLabTest'));
const Feedback = lazy(() => import('../../Pages/SpecialistsSearch/Feedback'));

const AppMain = () => {
    return (
        <Fragment>

            {/* Login Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/login" component={Login} />
            </Suspense>

            {/* Logout Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/logout" component={Logout} />
            </Suspense>

            {/* Forgot Password Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/forgot-password" component={ForgotPassword} />
            </Suspense>

            {/* reset Password Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/reset-password/:resetcode" component={ResetPassword} />
            </Suspense>

            {/* Verify mail Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/verify-email/:verificationcode" component={VerifyMail} />
            </Suspense>

            {/* Management Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/management" component={Management} />
            </Suspense>

 
            {/* Referral Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/referral" component={Referral} />
            </Suspense>


            
            {/* Specialist Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/specialist" component={Specialist} />
            </Suspense>           
            
            {/* Specialists Search Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
				<Route path="/specialists" component={SpecialistsSearch} />
				<Route path="/search-result/:location/:specialist" component={SearchResult} />
                <Route path="/specialist-details/:location/:specialist/:specialistslug" component={SpecialistDetailsPage} />
				<Route path="/appointment/:location/:specialist/:specialistslug/:date/:timeslot" component={BookAppointment} />
				<Route path="/confirm-appointment/:location/:specialist/:specialistslug/:date/:timeslot" component={ConfirmAppointment} />
				<Route path="/labs/patient-details" component={PatientDetails} />
				<Route path="/doctor/feedback/:id" component={Feedback} />
            </Suspense>           

            {/* Registration Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/registration" component={Registration} />
            </Suspense>           
            
            {/* Lab Test Route */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/test" component={LabTestSearch} />
                <Route path="/booklabtest" component={BookLabTest} />
                <Route path="/confirm-booklabtest/:testids/:date" component={ConfirmLabTest} />
            </Suspense>           

            {/* Default Route */}
            <Route exact path="/" render={() => (
                <Redirect to="/login" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;
